<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('update_register')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('update_register')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12 col-md-12">
                    <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <b-badge variant="primary"
                                         @click="changeStudentType"
                                         v-if="registerForm.nationality_code && registerForm.nationality_code.toUpper()==='TR'"
                                >
                                    Öğrenci
                                </b-badge>
                                <b-badge variant="warning"
                                         @click="changeStudentType"
                                         v-if="registerForm.nationality_code && registerForm.nationality_code.toUpper()!=='TR'"
                                >
                                    Yabancı Öğrenci
                                </b-badge>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('semester')">
                                    <div class="label-as-input">{{ getSemester(getObjectValue(registerForm, 'semester')) }}</div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('registration_type')">
                                    <div class="label-as-input">{{ getLocaleText(registerForm, 'type_name') }}</div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('scholarship_rate')">
                                    <div class="label-as-input">{{ getObjectValue(registerForm, 'scholarship_rate') }}</div>
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('faculty')">
                                    <div class="label-as-input">{{ getLocaleText(registerForm, 'faculty_name') }}</div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('department')">
                                    <div class="label-as-input">{{ getLocaleText(registerForm, 'department_name','-') }}</div>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('program')">
                                    <div class="label-as-input">{{ getLocaleText(registerForm, 'program_name') }}</div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{
                                    registerForm.nationality_code && registerForm.nationality_code.toUpper()!=='TR' ? $t('foreign_student_identity') : $t('identity')
                                }}
                            </template>
                            <div class="row"
                                 v-if="registerForm.nationality_code && registerForm.nationality_code.toUpper()==='TR'">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('tc_no')">
                                        <div class="label-as-input"> {{ getObjectValue(registerForm, 'national_id') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('surname')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'surname') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('date_of_birth')">
                                        <div class="label-as-input">{{ toLocaleDate(getObjectValue(registerForm, 'birthdate')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('birthplace')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'birthplace') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('gender')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'gender') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('nationality')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'nationality_code_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('mother_name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'mother_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('father_name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'father_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('city_name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'city_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('district_name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'district_name') }}</div>
                                    </b-form-group>
                                </div>

                            </div>
                            <div class="row" v-if="registerForm.nationality_code && registerForm.nationality_code.toUpper()!=='TR'">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('surname')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'surname') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('gender')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'gender') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('nationality')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'nationality_code_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('foreign_document_type')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'foreign_document_type_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('foreign_document_number')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'foreign_document_number') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('passport_country')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'passport_country_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('passport_number')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'passport_number') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('residence_permit_no')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'residence_permit_no') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('residence_permit_start_date')">
                                        <div class="label-as-input">{{ toLocaleDate(getObjectValue(registerForm, 'residence_permit_start_date')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('residence_permit_end_date')">
                                        <div class="label-as-input">{{ toLocaleDate(getObjectValue(registerForm, 'residence_permit_end_date')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('father_name')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'father_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('acceptance_letter_id')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm, 'acceptance_letter_id') }}</div>
                                    </b-form-group>
                                </div>

                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <i class="ri-map-pin-line top-plus-2 mr-2"></i>{{ $t('address') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('city')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'address_city_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('district')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'address_district') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <b-form-group :label="$t('address')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'address') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <i class="ri-team-line top-plus-2 mr-2"></i>{{ $t('family_info') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('mother_job')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'mothers_job') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('mothers_education')">
                                        <div class="label-as-input">{{ configEducation(getObjectValue(registerForm,'mothers_education')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('mother_gsm')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'mothers_phone') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('father_job')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'fathers_job') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('fathers_education')">
                                        <div class="label-as-input">{{ configEducation(getObjectValue(registerForm,'fathers_education')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('father_gsm')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'fathers_phone') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-phone-line top-plus-2 mr-2"></i>{{ $t('contact') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('gsm_no')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'mobile_tel') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('home_tel')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'home_tel') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('email')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'email') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 mb-2 mt-4">
                                    <h6 class="text-uppercase mb-0">{{ $t('emergency_contact_txt') | toUpperCase }}</h6>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('degree_of_proximity')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'emergency_person_relation') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('name_surname')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'emergency_person_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('mobile_number')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'emergency_person_phone') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-pulse-line top-plus-2 mr-2"></i>{{ $t('health') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                    <b-form-group :label="$t('surgery_illness_history')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'surgery_illness_history') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                    <b-form-group :label="$t('medicines')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'medicines') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('disability_type')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'type_of_disability_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('disability_rate')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'disability_rate') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <i class="ri-building-line top-plus-2 mr-2"></i>{{ $t('education_info') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('last_education_status')">
                                        <div class="label-as-input">{{ configEducation(getObjectValue(registerForm,'last_education_status')) }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                     v-if="registerForm.last_education_status != 'high_school'">
                                    <b-form-group :label="$t('last_school')">
                                        <div class="label-as-input"> {{ getObjectValue(registerForm,'last_university_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                     v-if="registerForm.lastEducationStatus == 'high_school'">
                                    <b-form-group :label="$t('last_school')">
                                        <div class="label-as-input"> {{ getObjectValue(registerForm,'school_name') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('last_school_registration_date')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'last_school_registration_date') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('last_school_date_of_graduation')">
                                        <div class="label-as-input"> {{ getObjectValue(registerForm,'last_school_date_of_graduation') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('last_school_diploma_number')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'last_school_diploma_number') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-form-group :label="$t('last_school_diploma_point')">
                                        <div class="label-as-input">{{ getObjectValue(registerForm,'last_school_diploma_point') }}</div>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{ $t('requests_info') }}</template>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <b-form-group :label="$t('want_leave_of_absence')">
                                        <div class="label-as-input">{{ getYesNoText(getObjectValue(registerForm,'want_leave_of_absence'), '-')}}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12"
                                     v-if="registerForm.want_leave_of_absence && registerForm.want_leave_of_absence=='E'">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                            <b-form-group :label="$t('leave_of_absence_reason')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'reason_for_leave_of_absence') }}</div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                            <b-form-group :label="$t('leave_of_absence_semesters')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'leave_of_absence_semesters') }}</div>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'TR'">
                                    <b-form-group :label="$t('want_optional_prep')" v-slot="{ ariaDescribedby }">
                                        <div class="label-as-input">{{getYesNoText(getObjectValue(registerForm,'want_optional_prep'), '-') }}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'EN'">
                                    <b-form-group :label="$t('do_you_have_language_proficiency')" v-slot="{ ariaDescribedby }">
                                        <div class="label-as-input">{{ getYesNoText(getObjectValue(registerForm,'has_english_proficiency_certificate'), '-')}}</div>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="row" v-if="registerForm.has_english_proficiency_certificate && registerForm.has_english_proficiency_certificate == 'E'">
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                            <b-form-group :label="$t('cert_type')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'english_proficiency_certificate_type') }}</div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                            <b-form-group :label="$t('exam_date')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'english_proficiency_exam_date') }}</div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                            <b-form-group :label="$t('exam_score')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'english_proficiency_exam_score') }}</div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                             v-if="registerForm.english_proficiency_exam_score && registerForm.english_proficiency_exam_score == 'toefl'">
                                            <b-form-group :label="$t('toefl_username')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'toefl_username') }}</div>
                                            </b-form-group>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                             v-if="registerForm.english_proficiency_certificate_type == 'toefl'">
                                            <b-form-group :label="$t('toefl_password')">
                                                <div class="label-as-input">{{ getObjectValue(registerForm,'toefl_password') }}</div>
                                            </b-form-group>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{ $t('documents') }}
                            </template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div v-for="(item,index) in documents" v-bind:key="index">
                                        <b-row no-gutters class="file-upload-box mb-1">
                                            <b-col sm="12" md="5" lg="4">
                                                <div class="filename d-flex" :class="{'active':item.uuid}">
                                                    <span class="flex-grow-1">{{ getLocaleText(item, 'name') }}</span>
                                                </div>
                                            </b-col>
                                            <b-col sm="12" md="5" lg="6">
                                                <b-input-group>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40 download"
                                                                  :v-b-popover.hover.right="$t('download')"
                                                                  v-if="item.uuid"
                                                                  @click="download(item.uuid)">
                                                            <i class="ri-download-2-line"></i>
                                                        </b-button>
                                                        <b-button variant="outline-secondary" class="btn-40"
                                                                  :v-b-popover.hover.right="$t('upload')"
                                                                  v-if="!item.uuid">
                                                            <i class="ri-add-fill"></i>
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import AppLayout from "@/layouts/AppLayout"

import ProgramService from "@/services/ProgramService";
import RegistrationService from "@/services/RegistrationService";

export default {
    components: {
        AppLayout,

        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('update_register')
        }
    },
    data() {
        return {
            education:[
                {value: "associate_degree",text:"ön Lisans",text_en:"Associate Degree"},
                {value: "graduate",text:"Mezun",text_en:"Graduate"},
                {value: "undergraduate",text:"Lisans",text_en:"Undergraduate"},
                {value: "high_school",text:"Lise",text_en:"High School"}
            ],

            university_status: null,
            foreignStudent: false,
            tabIndex: 1,
            programLanguage: null,
            university_type: ["Yurt İçi", "Yurt Dışı"],
            registerForm: {
                missingDocuments: null
            },

            leaveOfAbsenceSemesterOptions: [
                {value: null, text: this.$t('select')},
                {value: 1, text: this.$t('fall')},
                {value: 2, text: this.$t('spring')},
                {value: 3, text: this.$t('fall_and_spring')}
            ],

            documents: null,
            data: null,
            files: [],
            dates: [],

        }
    },
    methods: {
        nameWithLang({name}) {
            return `${name}`
        },

        getData() {
            if (this.$route.params.id) {
                RegistrationService.show(this.$route.params.id).then(response => {
                    let data = response.data.data
                    this.registerForm = data
                    this.registerForm.missingDocuments = data.missing_documents == null ? [] : data.missing_documents
                })
            } else {
                this.$router.push('/404')
            }
        },

        getDocuments() {
            RegistrationService.getDocuments(this.$route.params.id)
                .then(response => {
                    this.documents = response.data.data
                })
        },


        changeStudentType() {
            if (this.foreignStudent == true) {
                this.foreignStudent = false
            } else {
                this.foreignStudent = true

            }
        },

        isInterStudent(value) {
            if (value == 203 || value == 232 || value == 233 || value == 234 || value == 235) {
                this.foreignStudent = true
            } else {
                this.foreignStudent = false
            }
        },

        setProgramLanguage(programCode) {
            ProgramService.get(programCode).then(response => {
                this.programLanguage = response.data.data.language
            })
        },

        upload(index) {
            if (this.checkPermission("registration_document")) {
                setTimeout(() => {
                    if (this.documents[index]) {
                        let formData = new FormData()
                        formData.append('document_id', this.documents[index].id)
                        formData.append('file', this.files[index])
                        RegistrationService.uploadDocuments(this.$route.params.id, formData).then(response => {
                            this.getDocuments()
                            this.$toast.success(this.$t('api.' + response.data.message));
                        }).catch(e => {
                            if (e.status == 422) {
                                this.$toast.error(e.data.errors.file[0]);
                            }
                            if (e.status == 406) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }).finally(() => {
                            this.files[index] = null;
                        });
                    }
                }, 2000)
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        download(uuid) {
            if (this.checkPermission("registration_documentdownload")) {
                RegistrationService.downloadDocuments({uuid: uuid}).then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                }).catch(e => {
                    if (e.status == 422) {
                        this.$toast.error(e.data.errors.file[0]);
                    }
                    if (e.status == 406) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        remove(uuid) {
            if (this.checkPermission("registration_documentdelete")) {
                RegistrationService.deleteDocuments(uuid).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.getDocuments()
                }).catch(e => {
                    if (e.status == 422) {
                        this.$toast.error(e.data.errors.file[0]);
                    }
                    if (e.status == 406) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        setMissingDocuments() {
            setTimeout(() => {
                this.documents.forEach((item, index) => {
                    this.registerForm.missingDocuments.forEach((itm) => {
                        if (item.id == itm.document_type_id) {
                            this.dates[index] = itm.document_due_date
                        }
                    })
                })
            }, 2000)

        },
        configEducation(value){
            let result = this.education.find(edu=>edu.value==value)

            if(result){
                if(this.$i18n.locale=='tr') return result.text
                else return result.text_en
            }
            else return '-'
        }
    },

    watch: {
        'registerForm.program_code': {
            handler: function (val) {
                this.setProgramLanguage(val)
            }
        },

        'registerForm.type': {
            handler: function (val) {
                this.isInterStudent(val)
            }
        }
    },

    created() {
        this.getData()
        this.getDocuments()
        this.setMissingDocuments()
    }
};
</script>

